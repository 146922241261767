import { createApp } from 'vue'

export const settings = () => {
	const { $settings } = useNuxtApp()

	return $settings
}

export const getSettingsItem = (items, value) => {
	return items.filter((item) => item.value === value)[0]
}

export const getObjectByKey = (key, obj) => {
	return key.split('.').reduce((acc, part) => acc && acc[part], obj)
}

export const getSettingsTitle = (key, value) => {
	const result = getObjectByKey(key, settings()).find((item) => item.value == value)

	return result?.title ?? null
}

export const getProgressColor = (progress) => {
	if (progress <= 25) return 'error'
	if (progress > 25 && progress <= 50) return 'warning'
	if (progress > 50 && progress <= 75) return 'primary'
	if (progress > 75 && progress <= 100) return 'success'

	return 'secondary'
}

export const getFileType = (fileName) => {
	const fileExtension = fileName.split('.').pop()

	const { $settings } = useNuxtApp()

	const fileType = $settings.file_types.filter((item) => item.extensions.includes(fileExtension))

	if (fileType.length > 0) {
		return fileType[0]
	}
}

export const rand = (precision = 10000000) => {
	return Math.floor(Math.random() * precision)
}

export const removeRef = (data) => {
	return JSON.parse(JSON.stringify(data))
}

export const cleanRef = (data) => {
	return ref(JSON.parse(JSON.stringify(data)))
}

export const componentToString = (component, props = {}) => {
	const app = createApp(component, props)
	const container = document.createElement('div')
	const vm = app.mount(container)

	return vm.$el.outerHTML
}

export const days = () => {
	let days = []

	for (let i = 1; i <= 31; i++) {
		days.push({
			title: i,
			value: i,
		})
	}

	return days
}

export const groupItems = (items, count) => {
	const result = []

	for (let i = 0; i < items.length; i += count) {
		result.push(items.slice(i, i + count))
	}

	return result
}

export const generateUniqueId = (key = '') => {
	return key + '-' + Date.now() + '-' + getRandomInt()
}

export const getRandomInt = (min = 10000, max = 1000000000) => {
	const minCeiled = Math.ceil(min)
	const maxFloored = Math.floor(max)
	return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled)
}

export const snackbarSet = ({ isVisible = true, type = 'success', text = null } = {}) => {
	if (!text) {
		text = `snackbar.${type}`
	}

	return {
		isVisible,
		type,
		text,
	}
}

export const formatCost = (number) => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
